import { AccountAndBilling } from 'components/icons/account-and-billing'
import { AllContacts } from 'components/icons/allcontacts'
import { Analytics } from 'components/icons/analytics'
import { MainSchedule } from 'components/icons/main-schedule'
import { RasaContext } from 'context'
import React, { Component} from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import 'shared/notifications/_styles.scss'
import { DashboardMenuOption } from '../constants'
import './_styles.scss'
export { DashboardMenuOption } from '../constants'
interface DashboardMenuProps {
  notifications: any,
  flash: any,
  push: any,
  selected: DashboardMenuOption,
}

interface DashboardMenuState {
  showPopup: boolean,
}

const home = <Analytics svgwidth={35} svgheight={35}/>
const headerImgElement = <img src="https://cdn.zeplin.io/5d6023941db0449b12f2de7f/assets/3FC2EACA-BA79-4A4E-B77E-06E081E4B790.svg"
className="panel-generic-item-icon" style={{width: 35, height: 35}}></img>
// const analytics = <Analytics svgwidth={35} svgheight={35}/>
const schedule = <MainSchedule svgwidth={35} svgheight={35} />
const contacts = <AllContacts svgwidth={35} svgheight={35}/>
const billing = <AccountAndBilling svgwidth={35} svgheight={35}/>

class MobileDashboardMenuComponent extends Component<DashboardMenuProps, DashboardMenuState> {

  public static contextType = RasaContext;
  private _lastSelected: DashboardMenuOption = DashboardMenuOption.dashboard

  constructor(props: DashboardMenuProps) {
    super(props);
    this.state = {
      showPopup: false,
    }
    this._lastSelected = props.selected
  }

  public render() {
    return (
        <div className="mobile-dashboard">
          <div className="mobile-dashboard-menu">
            <this.Navigation icon={home} item={DashboardMenuOption.dashboard} name="Home"/>
            {/* <this.Navigation icon={analytics} item={DashboardMenuOption.analytics} name="Analytics"/> */}
            <this.Navigation icon={headerImgElement} item={DashboardMenuOption.content} name="Content"/>
            <this.Navigation icon={schedule} item={DashboardMenuOption.schedule} name="Schedule"/>
            <this.Navigation icon={contacts} item={DashboardMenuOption.contacts} name="Contacts"/>
            <this.Navigation icon={billing} item={DashboardMenuOption.accountSettings} name="Account"/>
          </div>
        </div>
    )
  }

  private Navigation = ({icon, item, name}: any) => {
    return <div className={this.getMenuItemStyle(item)} onClick={() => this.handleClick(item)}>
      <div className="mobile-nav-icon-wrapper">{icon}</div>
      <div className="mobile-nav-text-wrapper">{name}</div>
    </div>
  }

  private getMenuItemStyle(item: DashboardMenuOption) {
    if (this._lastSelected === item) {
      return 'mobile-dashboard-menu-item mobile-dashboard-menu-selected mobile-clickable-item'
    } else {
      return 'mobile-dashboard-menu-item mobile-clickable-item'
    }
  }

  private handleClick(item: DashboardMenuOption) {
    this.props.push(item);
  }
}

export const MobileDashboardMenu = connect(
  ({notifications, flash}: any) => ({notifications, flash}),
  {
    push: Router.push,
  },
)(MobileDashboardMenuComponent)
