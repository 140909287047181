import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'
import { ChunkProgressBar } from '@progress/kendo-react-progressbars'
import { TitleCell } from 'components/content-pool/kendocells'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { getCurrentPlanMaxSources } from 'shared_server_client/types/billing_plan'
import * as Constants from '../constants'
import { VideoComponent } from '../onboarding-videos'
interface SourcesState {
  loaded: boolean
  numberOfActiveSources: number
  sourcesToDisplay: []
  video?: Constants.DashboardVideo
}

type SourcesProps = any

export class OnboardingSourcesMobileComponent extends React.Component<SourcesProps, SourcesState> {
    public static contextType = RasaContext;
    private progressStyles = { background: '#72cac2' }

    constructor(p: any) {
      super(p, 'communitySource')
      this.state = {
        loaded: false,
        numberOfActiveSources: 0,
        sourcesToDisplay: [],
        video: Constants.DASHBOARD_VIDEOS[0],
      }
  }

  public componentDidMount() {
    this.context.user.init().then(({activeCommunity}) => {
      new Dataset()
        .loadCommunityDataset('communitySources', activeCommunity.communityId)
        .then((response) => {
          const sources = response[0] || []
          const activeSources = sources.filter((s) => !!s.is_active)
          this.setState({
            numberOfActiveSources: activeSources.length,
            sourcesToDisplay: activeSources.slice(0, 5),
          })
        })
    })
  }

  public render = () =>
    <div className="onboarding-chart-mobile onboarding-sources-chart">
        <Row>
            <Col sm="12">
              <VideoComponent videoWidth={4} video={this.state.video}/>
            </Col>
        </Row>
        <Row>
            <Col sm="6" className="progress-description">
              <span>At least 5 sources are recommended.</span>
            </Col>
        </Row>
        <Row>
            <Col sm="12" className="progress-sources">
                <ChunkProgressBar
                value={this.state.numberOfActiveSources}
                className="chunk-progress"
                style={{ width: '100%' }}
                min={0}
                max={5}
                progressStyle={this.progressStyles}/>
            </Col>
        </Row>
        <br/>
        <Row>
        <Grid data={this.state.sourcesToDisplay}>
          <Column field="name" title="Name" editable={false} cell={TitleCell}/>
          <Column field="last_week_total" title="# Upcoming" editable={false} className="number-upcoming" width="125%"/>
        </Grid>
      </Row>
      {!!this.canAddSource() &&
      <Row className="addsources-row">
        <a className="add-sources-button-onboarding" href={`${DashboardMenuOption.content}/addsource`}>
            + Add source</a>
      </Row>}
    </div>

  private canAddSource(): boolean {
    if (this.context.store.getState().config.isDesktop) {
      if (this.context.user.activeCommunity && this.context.user.activeCommunity.billingInfo) {
        const maxAllowed: number =
          getCurrentPlanMaxSources(this.context.user.activeCommunity.billingInfo.currentPlan)
        return maxAllowed > this.state.numberOfActiveSources
      }
    }
    return false
  }
}
