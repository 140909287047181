import { RasaContext } from 'context';
import { RasaReactComponent } from 'generic/rasaReactComponent';
import React from 'react'
import { Col, Row } from 'reactstrap';
import * as Constants from './constants'

interface VideoProps {
  hideVideo?: boolean,
  hideContent?: boolean,
  video: Constants.DashboardVideo,
  videoWidth: number,
}
export class VideoComponent extends RasaReactComponent<VideoProps> {
  public static contextType = RasaContext;

    public componentWillMount() {
      const script1 = document.createElement('script')
      script1.src = 'https://fast.wistia.com/assets/external/E-v1.js'
      script1.async = true
      document.body.appendChild(script1)

      Constants.DASHBOARD_VIDEOS.forEach((v: Constants.DashboardVideo) => {
        const s = document.createElement('script')
        s.src = Constants.scriptUrl(v)
        s.async = true
        document.body.appendChild(s)
      })
    }

    public render() {
      return this.props.video === Constants.DASHBOARD_VIDEOS[0] && this.context.store.getState().config.isMobile ?
          <Col>
          {!this.props.hideVideo &&
          <Col sm={12}>
          <div dangerouslySetInnerHTML={this.videoHtml()}/>
          </Col>}
          {!this.props.hideContent &&
          <Row sm={12} className="mobile-video-row">
            <Row className="title">
              {this.props.video.title}
            </Row>
            <Row className="description">
              {this.props.video.description}
            </Row>
          </Row>}
          </Col>
          :
          <Row>
          {!this.props.hideVideo &&
          <Col sm={this.props.videoWidth || 4}>
            <div dangerouslySetInnerHTML={this.videoHtml()}/>
          </Col>}
          {!this.props.hideContent &&
          <Col sm={12 - (this.props.videoWidth || 4)}>
            <Row className="title">
              {this.props.video.title}
            </Row>
            <Row className="description">
              {this.props.video.description}
            </Row>
          {this.props.video.clickUrl ?
            <Row className="links">
              <a href={this.props.video.clickUrl}>
                <button>{this.props.video.clickTitle || this.props.video.clickUrl}</button>
              </a>
            </Row> : null}
          </Col>}
          </Row>
    }

    private videoHtml() {
      return {
        __html: `
          <div class='wistia_responsive_padding' style='padding:60% 0 0 0;position:relative;'>
            <div class='wistia_responsive_wrapper' style='height:100%;left:0;position:absolute;top:0;width:100%;'>
              <div class='wistia_embed wistia_async_${this.props.video.guid} videoFoam=true' style='height:100%;position:relative;width:100%'>
                <div class='wistia_swatch' style='height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;'>
                  <img src='https://fast.wistia.com/embed/medias/${this.props.video.guid}/swatch' style='filter:blur(5px);height:100%;object-fit:contain;width:100%;' alt='' aria-hidden='true' onload='this.parentNode.style.opacity=1;' />
                </div>
              </div>
            </div>
          </div>`,
      }
    }
  }
