import { RSSFeedIcon } from 'components/icons/rssfeed';
import React from 'react'
import { SourceTypes } from 'shared_server_client/constants';

const rssFeed = <RSSFeedIcon svgwidth={40} svgheight={40}/>

export interface Source {
  name: string,
  checked: boolean,
  fetch_url?: string,
  fetched_sources: any[],
  find_url?: string,
  icon: JSX.Element,
  invalid_url_message: string,
  showTextbox?: boolean,
  site_url?: string,
  source_type: SourceTypes,
  title: string,
  url?: string,
  url_placeholder?: string,
}

export const TWITTER_FOOTER_LINK_PREFIX = 'https://twitter.com/'

export const FACEBOOK_FOOTER_LINK_PREFIX = 'https://www.facebook.com/'

export const LINKEDIN_FOOTER_LINK_PREFIX = 'https://www.linkedin.com/'

export const FEED_SOURCE = 'feed'

export const Sources: Source[] = [{
    name: FEED_SOURCE,
    checked: true,
    fetch_url: 'feed/fetch',
    fetched_sources: [],
    find_url: 'feed/find',
    icon: rssFeed,
    invalid_url_message: 'Invalid Feed URL',
    source_type: SourceTypes.rss,
    title: 'RSS Feed',
    url_placeholder: 'Enter RSS Feed URL',
  },
]

export enum ONBOARDING_STEPS {
  SOURCE_ONBOARDING = 1,
  DESIGN_ONBOARDING = 2,
  TEMPLATE_ONBOARDING = 3,
}

export enum FOOTER_TEXT {
  SOURCE_ONBOARDING = 'Tip: Need help finding content sources to add? \
    Contact support or explore our content library on the content page.',
  DESIGN_ONBOARDING = 'Tip: you can edit these on the design page once you finish setting up your account.',
  TEMPLATE_ONBOARDING = 'Tip: You can change and customize this template further in the dashboard.',
}
