import { IntegrationSystems } from 'shared/data-layer/integration'
import { BillingPlan, BillingSystemCode, isFreePlan } from 'shared_server_client/types/billing_plan'
import { UsageStats } from 'shared_server_client/types/usage_stats'
import { User } from 'user'

const HIDE_ANNOUNCEMENT_SESSION_STORAGE_KEY = 'hideAnnouncement'
export const isAppSumoSignup = (state: any, user: User): boolean => {
  return  state.app.params.signup &&
          user.activeCommunity.billingInfo.currentPlan.billing_system_code === BillingSystemCode.APP_SUMO
}

export const isIncompleteHubSpotAccount = (state: any, user: User): boolean => {
  return  user.activeCommunity.accountSource === 'hubspot' && !HubSpotUtils.signupComplete(user)
}

export const getHideAnnouncementStorageKey = (announcementId: number) => {
  return `${HIDE_ANNOUNCEMENT_SESSION_STORAGE_KEY}_${announcementId}`
}

export class HubSpotUtils {

  public static signupComplete(user: User): boolean {
    return !HubSpotUtils.needsPlanSignup(user) &&
           !HubSpotUtils.needsHubSpotConnect(user) &&
           !HubSpotUtils.needsNewsletter(user)
  }

  public static needsPlanSignup(user: User): boolean {
    const billingPlan: BillingPlan = user.activeCommunity.billingInfo.currentPlan
    return isFreePlan(billingPlan)
  }

  public static needsHubSpotConnect(user: User): boolean {
    const hubSpot: any = user.person.connectedCommunitySystems.find((s) => s.name === IntegrationSystems.Hubspot)
    return !hubSpot
  }

  public static needsNewsletter(user: User): boolean {
    if (user.activeCommunity.billingInfo.usageStats.numNewsletters > 1) {
      return false
    } else {
      const usageStats: UsageStats = user.activeCommunity.billingInfo.usageStats
      return usageStats.sourcesCount === 0
      }
  }
}
