import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as React from 'react'
import { Col, Row } from 'reactstrap'

import { ClickOutside } from 'components/click-outside/component'
import { RasaLogo } from 'components/icons/rasalogo'
import { RasaContext } from 'context'
import { CommunityDropdown } from '../../community-dropdown/component'
import { Dataset } from '../../../generic/dataset'

interface WelcomeState {
  brand_header_image_url?: string,
  firstName?: string,
  lastName?: string,
  drop: boolean,
  communities: any[],
}

const emptyState: WelcomeState = {drop: false, communities: []}

type WelcomeProps = GenericRedux.ComponentActionProps

class WelcomeComponent extends RasaReactComponent<WelcomeProps, WelcomeState> {
  public static contextType = RasaContext;
  constructor(p: any) {
    super(p, 'communitySubscriberCounts', emptyState)
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        brand_header_image_url: activeCommunity.data.brand_header_image_url,
        firstName: person.firstName,
        lastName: person.lastName,
      })
      this.loadRecord(activeCommunity.communityId, activeCommunity.communityId)
      this.loadCommunities()
    })
  }

  private loadCommunities = () => {
    return new Dataset().loadCommunityDataset('userNewsletters')
      .then((communities) => {
        this.setState({
          communities: communities[0],
        })
      })
  }

  public render = () => {
    return <Row className="mobile-welcome-section">
      <Col sm={8}>
          <Row>
              <RasaLogo/>
          </Row>
        <Row>
          <Row className={'mobile-w-nowrap'}>
            <Col sm={9}>
              <div className="mobile-section-header-text">Dashboard</div>
            </Col>
            <Col sm={3}>
              {this.state.communities.length > 1 && <CommunityDropdown />}
            </Col>
          </Row>
        </Row>
        <Row>
          <div className="mobile-welcome">Welcome{this.state.firstName && `, ${this.state.firstName}`}!</div>
        </Row>
        <br/>
      </Col>
      <Col sm={4} className="mobile-logo">
        <div className="mobile-left-header-flex">
          <ClickOutside handleClickOutside={() => this.state.drop ? this.setState({drop: !this.state.drop}) : null}>
          <div className="dropdown-container">
            { this.state.drop ?
                <div className="mobile-dropdown-content">
                  <a className="mobile-drop-link" onClick={() => this.props.push('/content/addarticle')}
                     href="#">
                    Article
                  </a>
                  <a className="mobile-drop-link" onClick={() => this.props.push('/contacts/add-contact')}
                     href="#">
                    Contact
                  </a>
                  <a className="mobile-drop-link" onClick={() => this.props.push('/content/addsource')}
                     href="#">
                    Source
                  </a>
              </div>
              : null}
          </div>
          </ClickOutside>
        </div>
      </Col>
    </Row>
  }
}

export const MobileWelcome = GenericRedux.registerNewComponent(WelcomeComponent, 'mobile_home_welcome', null)
