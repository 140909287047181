import * as React from 'react'
import {connect} from 'react-redux'
import * as Router from 'router'

import './_styles.scss'
import { OnboardingMobileDashboard } from './charts'
import { MobileWelcome } from './welcome'


const DashboardComponent = () => <div className="mobile-dashboard-wrapper fade-in one">
      <div>
        <MobileWelcome/>
      </div>
      <div>
        <OnboardingMobileDashboard />
      </div>
      <br/>
      <br/>
    </div>

export const MobileDashboard = connect(
  null,
  {
    push: Router.push,
  },
)(DashboardComponent)
