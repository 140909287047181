import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { RasaContext } from 'context'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as React from 'react'
import { SmallCardComponent } from './small-card'

export class OnboardingDesignComponent extends RasaReactComponent<any> {
  public static contextType = RasaContext
    public render = () => <SmallCardComponent
                            button={true}
                            buttonText="Go to Design"
                            description="Customize your email template."
                            iconName="design"
                            iconSize={100}
                            linkUrl={DashboardMenuOption.emailDesign}
                            mobile={this.context.store.getState().config.isMobile}
                            mobileText="Design available on desktop"
                            status={this.state.domainAuthSet}
                            title="Design Your Newsletter"
                            />
}
