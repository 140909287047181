import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { RasaContext } from 'context'
import * as React from 'react'
import {SmallCardComponent} from './small-card'
interface ContactsState {
    hasContacts: boolean,
}

type ContactsProps = any
export class OnboardingContactsComponent extends React.Component<ContactsProps, ContactsState> {
    public static contextType = RasaContext;
    constructor(p: any) {
      super(p, 'communitySource')
      this.state = {
        hasContacts: false,
      }
  }

  public componentDidMount() {
    this.context.user.init().then(({ activeCommunity }) => {
      this.setState({
        hasContacts: activeCommunity._communityInfo.data.community[0].has_subscribers,
      })
    })
  }

  public render = () => <SmallCardComponent
                          description="Add a contact or import your csv file."
                          iconName="allContacts"
                          iconSize={30}
                          linkUrl={DashboardMenuOption.addContacts}
                          status={this.state.hasContacts}
                          title="Add Contacts"
                          />
}

export const SENDER_REQUIRED_CONTACTS_COUNT = 30
