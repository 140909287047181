import { HeaderIcon } from 'components/icons/header-icon'
import React from 'react'

export const SmallCardComponent = (props) => {
  return (
    <div className="onboarding-chart-small">
      <a href={props.linkUrl}>
        <div className="small-card-wrapper">
          <div className="icon-container">
            <HeaderIcon name={props.iconName} width={props.iconSize} height={props.iconSize}/>
          </div>
          <div className="title-description-status-container">
            <div className="title-description-container">
              <div className={`title ${props.iconName}-title`}>{props.title}</div>
              <div className="description">{props.description}</div>
            </div>
            <div className="status-or-button-container">
              {props.button ?
              <button className="button-go-to">{props.buttonText}</button> :
              props.status ? (
                <div className="complete">Complete</div>
              ) : (
                <div className="incomplete">Incomplete</div>
              )}
            {!!props.mobile &&
            <div className="mobile-only-text">
              <b>{props.mobileText}</b>
            </div>}
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}
