import { ClickOutside } from 'components/click-outside/component'
import { CarrotDown } from 'components/icons/carrot-down'
import { CarrotUp } from 'components/icons/carrot-up'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { AddNewsletterModal } from '../communities/modals'

interface WelcomeState {
  brand_header_image_url?: string,
  firstName?: string,
  lastName?: string,
  drop: boolean
}

const emptyState: WelcomeState = {drop: false}

interface SubscriberCounts {
  total: number,
}

type WelcomeProps = GenericRedux.AllComponentPropsWithModal<any> & SubscriberCounts

class WelcomeComponent extends RasaReactComponent<WelcomeProps, WelcomeState> {
  public static contextType = RasaContext;
  constructor(p: any) {
    super(p, 'communitySubscriberCounts', emptyState)
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        brand_header_image_url: activeCommunity.data.brand_header_image_url,
        firstName: person.firstName,
        lastName: person.lastName,
      })
      this.loadRecord(activeCommunity.communityId, activeCommunity.communityId)
    })
  }

  public render = () => {
    return <Row className="welcome-section">
      <Col sm={8}>
        <Row>
          <div className="section-header-text home">Dashboard</div>
        </Row>
        <Row>
          <div className="welcome">Welcome{this.state.firstName && `, ${this.state.firstName}`}!</div>
        </Row>
        {this.props.total &&
        <Row>
          <div className="summary">
          Complete the below steps to start sending your newsletter:</div>
        </Row>}
      </Col>
      <Col sm={4} className="logo">
        <div className="left-header-flex">
          <ClickOutside handleClickOutside={() => this.state.drop ? this.setState({drop: !this.state.drop}) : null}>
          <div className="dropdown-container">
            <Button onClick={() => this.setState({drop: !this.state.drop})} className="dropdown-button">
              Add New &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              { this.state.drop ? <CarrotUp className="carrot"/> : <CarrotDown className="carrot"/>}
            </Button>
            { this.state.drop ?
              <div className="dropdown-content">
                <a className="drop-link"
                   onClick={(e) => {
                     e.preventDefault()
                     this.props.push('/content/addarticle')
                   }}
                   href="#">
                  Article
                </a>
                <a className="drop-link"
                   onClick={(e) => {
                     e.preventDefault()
                     this.props.push('/contacts/add-contact')
                   }}
                   href="#">
                  Contact
                </a>
                <a className="drop-link"
                   onClick={(e) => {
                     e.preventDefault()
                     this.props.push('/content/addsource')
                   }}
                   href="#">
                  Source
                </a>
                <a className="drop-link"
                   onClick={(e) => {
                     e.preventDefault()
                     this.props.openModal(AddNewsletterModal.key, null)
                   }}
                   href="#">
                  Newsletter
                </a>
              </div>
              : null}
          </div>
          </ClickOutside>
        </div>
      </Col>
      <AddNewsletterModal
        data={this.props.modals}
        closeModal={this.props.closeModal}
        saveModal={this.props.saveModal}/>
    </Row>
  }

}

export const Welcome = GenericRedux.registerNewComponentWithModals<any>(
  WelcomeComponent,
  'home_welcome',
  [AddNewsletterModal.key],
  {})
