import React from 'react'
import * as Modals from 'shared/modals'
import { getHideAnnouncementStorageKey } from '../utils'

interface AnnouncementState extends Modals.ModalComponentState {
  dontShowAgain: boolean,
}

export class Announcement extends Modals.GenericModalComponent<Modals.ModalComponentProps, AnnouncementState> {

  public static key: string = 'announcement'

  constructor(props: Modals.ModalComponentProps) {
    super(props, Announcement.key)
  }

  protected showHeader(data: any) {
    return true
  }

  protected getTitleText(data: any): string {
    return data && data.title ? data.title : 'Announcement'
  }

  protected getCloseText(data: any): string {
    return 'Close'
  }

  protected onModalClose(data: any): void {
    if (this.state.dontShowAgain && data) {
      sessionStorage.setItem(getHideAnnouncementStorageKey(data.id), 'true')
    }
    this.props.closeModal(this.key)
  }

  protected renderChildren(data: any) {
    return data ? <div className="announcement-modal">
      <div className="content" dangerouslySetInnerHTML={{__html: data.description}}>
      </div>
      <div className="dont-show-again">
        <div className="checkbox" onClick={() => this.onCheckToggle()}>
          <button className="checkbox-button">
            <div className="feature-icon">{this.state.dontShowAgain ? <i className="fas fa-check"></i> : null}</div>
          </button>
          <label>Don't show again.</label>
        </div>
      </div>
    </div> : null
  }

  private onCheckToggle() {
    this.setState({
      dontShowAgain: !this.state.dontShowAgain,
    })
  }
}
