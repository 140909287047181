import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { RasaContext } from 'context'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as React from 'react'
import { SmallCardComponent } from './small-card'

interface DomainAuthState {
    domainAuthSet: boolean,
}

type DomainAuthProps = any
export class OnboardingDomainAuthComponent extends RasaReactComponent<DomainAuthProps, DomainAuthState> {
    public static contextType = RasaContext;
    constructor(p: any) {
      super(p, 'communitySource')
      this.state = {
        domainAuthSet: false,
        isDirty: false,
        isLoading: false,
        isSaving: false,
      }
  }

  public componentDidMount() {
    this.context.user.init().then(({activeCommunity}) => {
      this.setState({
        domainAuthSet: this.authenticatedDomain(activeCommunity._communityInfo.data.community[0].from_email),
      })
    })
  }

  public render = () => <SmallCardComponent
                          description="Send your newsletter from your own email address."
                          iconName="domainAuthentication"
                          linkUrl={DashboardMenuOption.domainAuthentication}
                          mobile={this.context.store.getState().config.isMobile}
                          mobileText="Domain Authentication available on Desktop"
                          iconSize={30}
                          status={this.state.domainAuthSet}
                          title="Authenticate Domain"
                          />

  private authenticatedDomain = (fromEmail: string): boolean => {
    return fromEmail && fromEmail.includes('@') && !fromEmail.includes('rasa.io')
  }
}
