import { UpgradeAnchorLink } from 'components/link'
import { MenuComponentProps } from 'components/tab-menu/component'
import { RasaContext } from 'context'
import * as GA from 'google-analytics'
import React, {Component} from 'react'
import { BillingPlan, isFreePlan, ProductSubscription } from 'shared_server_client/types/billing_plan'

interface AccountDetailsState {
    communityId: string,
    productSubscription: ProductSubscription,
    currentPlan?: BillingPlan,
    hostedPaymentPageUrl: string,
    freePlanSelected: boolean,
    latestInvoice?: any,
    usageStats?: any,
  }
export class AccountUsageStats extends Component<MenuComponentProps, AccountDetailsState> {
  public static contextType = RasaContext

  constructor(props: MenuComponentProps) {
    super(props);
    this.state = {
      productSubscription: null,
      communityId: null,
      hostedPaymentPageUrl: null,
      freePlanSelected: false,
    }

  }

  public componentDidMount() {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        communityId: activeCommunity.communityId,
        productSubscription: activeCommunity.billingInfo.productSubscription,
        currentPlan: activeCommunity.billingInfo.currentPlan,
        usageStats: activeCommunity.billingInfo.usageStats,
      })
    })
  }

  public render() {
    return (
      this.getCurrentPlanUsageJSX()
    )
  }

  private formatNumber(value: number, decimals: number): string {
    if (value !== null) {
      return value.toLocaleString(
        undefined, // leave undefined to use the browser's locale,
                   // or use a string like 'en-US' to override it.
        { minimumFractionDigits: decimals },
      )
    } else {
      return ''
    }
  }

  private getCurrentPlanUsageJSX(): JSX.Element {
    if (this.showPlanUsage()) {
      return <div className="usage-wrapper">
              {this.state.usageStats && this.state.currentPlan && (
                <div className="settings-account-billing-plan-header first" >
                  <div className="linked-widget-wrapper">
                    <UpgradeAnchorLink source={GA.UpgradeSource.UsageWidget}>
                      <div className="messages-sent">{this.maxReached()}</div>
                        <div className="header-usagewrap">
                          <div className="header-settings-account-billing-plan-usage-gauge"
                          >
                          <div className="header-settings-account-billing-plan-usage-so-far"
                              title={`${this.formatNumber(this.state.usageStats.numActualMessages, 0)} used so far this billing period`}
                              style={{width: `${this.state.usageStats.usagePercent < 100 ? this.state.usageStats.usagePercent : 100}%`,
                                    backgroundColor: this.state.usageStats.usagePercent < 100 ? '#72cac2' : 'firebrick',
                                    borderTopRightRadius: this.state.usageStats.usagePercent > 100 ? '5px' : '',
                                    borderBottomRightRadius: this.state.usageStats.usagePercent > 100 ? '5px' : '',
                                    borderTopLeftRadius: '5px',
                                    borderBottomLeftRadius: '5px',
                              }}>
                          </div>
                          <div className="header-settings-account-billing-plan-projected"
                              title={`${this.formatNumber(this.state.usageStats.numProjectedMessages, 0)} projected through end of billing period for a total of ${
                                this.formatNumber(this.state.usageStats.numProjectedMessages + this.state.usageStats.numActualMessages, 0)}`}
                              style={{
                                width: `${this.state.usageStats.cappedProjectedPercent}%`,
                                // override border radius on right side if we're going above 100%
                                borderTopRightRadius: this.state.usageStats.projectedOveragePercent > 0 ? '5px' : '',
                                borderBottomRightRadius: this.state.usageStats.projectedOveragePercent > 0 ? '5px' : '',
                                backgroundColor: this.state.usageStats.projectedOveragePercent > 0 ? 'firebrick' : '',
                              }}>
                          </div>
                          </div>
                        </div>
                        <div className="messages-sent-numbers">
                            <span className="header-account-billing-plan-usage-data first">
                              {this.formatNumber(this.state.usageStats.numProjectedMessages +
                              this.state.usageStats.numActualMessages, 0)}
                            </span>
                            <span className="header-account-billing-plan-usage-data-inner-label"> / </span>
                            <span className="header-account-billing-plan-usage-data">
                              {this.formatNumber(this.state.usageStats.currentPlanIncludedMessages, 0)}
                            </span>
                        </div>
                      </UpgradeAnchorLink>
                    </div>
                    <UpgradeAnchorLink source={GA.UpgradeSource.UsageWidget}
                      style={{
                        color: this.state.usageStats.numProjectedMessages >
                        this.state.usageStats.currentPlanIncludedMessages ?
                        'firebrick' : 'mediumaquamarine',
                        margin: '0.25rem',
                      }}
                      className="messages-sent-upgrade">
                      Upgrade
                    </UpgradeAnchorLink>
                </div>)}
            </div>
            } else {
                return <div/>
            }
  }

  private showPlanUsage(): boolean {
    return (isFreePlan(this.state.currentPlan)
    && this.state.usageStats
    && !this.state.usageStats.hasUnlimitedMessages
    && this.state.usageStats.numActualMessages > 100)
  }

  private maxReached() {
    const msgsIncluded: number = this.state.usageStats.currentPlanIncludedMessages
    const msgsSent: number = this.state.usageStats.numActualMessages
    if (msgsIncluded <= msgsSent) {
      return <div className="max-reached">
        Max Reached
      </div>
    } else {
      return 'Projected'
    }
  }
}
