import { AnchorLink } from 'components/buttons';
import { DashboardMenuOption } from 'components/dashboard-menu/constants';
import { HubspotIcon } from 'components/icons/hubspot';
import { UpgradeAnchorLink } from 'components/link';
import { addNewsletterUrl } from 'generic/utility'
import * as GA from 'google-analytics'
import React from 'react'
import * as Modals from 'shared/modals'
import { User } from 'user'
import { HubSpotUtils } from '../utils'
import './styles.scss'

export class HubSpotWelcomeAboard extends Modals.ModalComponent {

  public static key: string = 'hsWelcomeAboard'
  constructor(props: Modals.ModalComponentProps) {
    super(props, HubSpotWelcomeAboard.key)
  }

  protected showHeader(data: any) {
    return false
  }

  protected renderChildren(data: User) {
    if ( !data.activeCommunity || !data.activeCommunity.billingInfo ) {
      return null
    }
    return <div className="hubspot-welcome">
      <HubspotIcon/>
      <div>
        <h1>Welcome Aboard!</h1>
        <div className="content">
          Here's what you need to do next:
          {this.planSignup(data)}
          {this.connectHubspot(data)}
          {this.addNewsletter(data)}
        </div>
      </div>
    </div>
  }

  private planSignup(user: User) {
    if ( HubSpotUtils.needsPlanSignup(user) ) {
      return <div>
        <span>
          1. Select your plan&nbsp;
          <UpgradeAnchorLink source={GA.UpgradeSource.HubSpot}>
            here
          </UpgradeAnchorLink>
        </span>
      </div>
    } else {
      return <div>
        <span><i className="fas fa-check"></i>You've selected your plan!</span>
      </div>
    }
  }

  private connectHubspot(user: User) {
    if ( HubSpotUtils.needsHubSpotConnect(user) ) {
      return <div>
        <span>
          2. Connect your HubSpot and rasa.io accounts&nbsp;
          <AnchorLink href={`${DashboardMenuOption.settings}/integrations`}>here</AnchorLink>
        </span>
      </div>
    } else {
      return <div>
        <span><i className="fas fa-check"></i>You've connected your accounts!</span>
      </div>
    }
  }

  private addNewsletter(user: User) {
    if ( HubSpotUtils.needsNewsletter(user) ) {
      return <div>
        <span>
          3. Build your first newsletter&nbsp;
          <a href={addNewsletterUrl({signup: true})}>here</a>
        </span>
      </div>
    } else {
      return <div>
        <span><i className="fas fa-check"></i>You've built your first newsletter!</span>
      </div>
    }

  }

}
