import { DashboardMenuOption } from 'components/dashboard-menu/constants';
import { RasaContext } from 'context'
import * as React from 'react'
import { SmallCardComponent } from './small-card'

interface ScheduleState {
    scheduleSet: any,
}

type ScheduleProps = any

export class OnboardingScheduleComponent extends React.Component<ScheduleProps, ScheduleState> {
    public static contextType = RasaContext;
    constructor(p: any) {
      super(p, 'communitySource')
      this.state = {
        scheduleSet: '',
      }
  }

  public componentDidMount() {
    this.context.user.init().then(({activeCommunity}) => {
      this.setState({
        scheduleSet: activeCommunity._communityInfo.data.schedule[0].start_date,
      })
    })
  }

  public render = () => <SmallCardComponent
                          description="Set the send schedule for your newsletter."
                          iconName="schedule"
                          linkUrl={DashboardMenuOption.schedule}
                          iconSize={30}
                          status={this.state.scheduleSet}
                          title="Set Schedule"
                          />
}
