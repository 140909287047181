import * as React from 'react'
import {connect} from 'react-redux'
import { Col, Row } from 'reactstrap'
import { OnboardingContactsComponent } from './contacts'
import { OnboardingDesignComponent } from './design'
import { OnboardingDomainAuthComponent } from './domainauth'
import { OnboardingScheduleComponent } from './schedule'
import { OnboardingSourcesComponent } from './sources'
class OnboardingDashboardComponent extends React.Component<any> {

  constructor(p: any) {
    super(p)
  }

  public render = () => {
    return <div className="option-wrapper">
      <div className="clearfix"></div>
      <div className="onboarding-dashboard-container">
        <Row>
        <Col sm={6} xs={12}>
            <OnboardingSourcesComponent />
        </Col>
        <Col sm={6} xs={12}>
          <Row sm={4}>
            <OnboardingDesignComponent/>
          </Row>
          <Row sm={4}>
            <OnboardingContactsComponent/>
          </Row>
          <Row sm={4}>
            <OnboardingScheduleComponent/>
          </Row>
          <Row sm={4}>
            <OnboardingDomainAuthComponent/>
          </Row>
        </Col>
        </Row>
      </div>
    </div>
  }
}

export const OnboardingDashboard = connect(
  ({app}: any) => ({app}),
  {},
)(OnboardingDashboardComponent)
