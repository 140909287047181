import { sizedUrl } from 'app/utils'
import { SelectColor } from 'components/colors/component'
import { DropdownWithDividersComponent } from 'components/dropdown/component'
import { FONT_FAMILIES, getFontFamily, HEADER_LOGO_MAX_WIDTH, Image, Text } from 'components/email-layout/constants'
import { BigAndBoldTemplate } from 'components/icons/template-big-and-bold'
import { ClassicTemplate } from 'components/icons/template-classic'
import { InsightsTemplate } from 'components/icons/template-insights'
import { NewspaperTemplate } from 'components/icons/template-newspaper'
import { Upload } from 'components/icons/upload2'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import React from 'react'
import Dropzone from 'react-dropzone'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import * as Modals from 'shared/modals'
import { EmailAttributes, EmailTemplates, IMAGE, SubscriptionTypes } from 'shared_server_client/constants'
import { User } from 'user'
import { FACEBOOK_FOOTER_LINK_PREFIX, FEED_SOURCE, FOOTER_TEXT, LINKEDIN_FOOTER_LINK_PREFIX,
  ONBOARDING_STEPS, Source, Sources, TWITTER_FOOTER_LINK_PREFIX } from './constants'

interface OnboardingModalState extends Modals.ModalComponentState {
  accountId: string,
  articlesLoading: boolean,
  colors: string[],
  companyName: string,
  companyInfo: any,
  communityId: string,
  emailLayout: any,
  emailLayoutId: string,
  emailTemplate: EmailTemplates,
  footerText: string,
  isLoading: boolean,
  isSaving: boolean,
  previewImages?: Image[],
  previewTexts?: Text[],
  sources: Source[],
  onboardingStep: number,
  website: string,
}
const MAX_STEPS_ALLOWED = 3
export class OnboardingModal extends Modals.GenericModalComponent<any, OnboardingModalState> {

  public static contextType = RasaContext
  public static key: string = 'NewOnboarding'
  private sharedStore: SharedStore
  constructor(props: Modals.ModalComponentProps) {
    super(props, OnboardingModal.key)
    this.state = {
      accountId: '',
      articlesLoading: false,
      colors: [],
      companyName: '',
      companyInfo: {},
      communityId: '',
      emailTemplate: EmailTemplates.default,
      emailLayout: {},
      emailLayoutId: '',
      footerText: FOOTER_TEXT.SOURCE_ONBOARDING,
      isLoading: true,
      isSaving: false,
      onboardingStep: 1,
      previewImages: [],
      previewTexts: [],
      sources: [],
      website: '',
    }
    this.fetchCompanyInfo = this.fetchCompanyInfo.bind(this)
  }

  public componentDidMount(): void {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
      .then((activeCommunity) => {
        const accountOptions = JSON.parse(activeCommunity.data.account_options || '{}')
        this.setState({
          accountId: activeCommunity.data.account_id,
          communityId: activeCommunity.communityId,
          companyName: activeCommunity.data.account_company_name,
          emailLayoutId: activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].id,
          onboardingStep: accountOptions.onboarding_step || ONBOARDING_STEPS.SOURCE_ONBOARDING,
          website: activeCommunity.data.account_website,
        }, () => {
          if (this.state.onboardingStep === ONBOARDING_STEPS.SOURCE_ONBOARDING ||
            this.state.onboardingStep === ONBOARDING_STEPS.DESIGN_ONBOARDING) {
            if ( this.state.website ) {
              this.fetchCompanyInfo()
            }
          }
          if (this.state.onboardingStep !== ONBOARDING_STEPS.SOURCE_ONBOARDING) {
            this.getEmailLayoutInfo()
          }
          this.setFooterText()
        })
      })
  }

  protected showHeader(data: any) {
    return true
  }

  protected getSaveText(data: any): string {
    return 'Next'
  }

  protected onModalClose(data: any): void {
    this.props.closeModal(this.key)
    this.context.entityMetadata.getEntityObject('account').then(
      (entityObject: any ) => {
      entityObject.load(this.state.communityId, this.state.accountId).then((account) => {
        const options = {
          ...JSON.parse(entityObject.data.options || '{}'),
          skip_onboarding: true,
        }
        entityObject.setRecordId(this.state.communityId, this.state.accountId)
        entityObject.data.options = JSON.stringify(options)
        entityObject.save().then(() => {
          this.sharedStore.refresh()
        })
      })
    })
  }

  protected save(data: any): Promise<any> {
    this.setState({
      isSaving: true,
    })
    return this.doStepSave().then(() => this.context.entityMetadata.getEntityObject('account').then(
      (entityObject: any ) => {
      entityObject.load(this.state.communityId, this.state.accountId).then((account) => {
        const currentStep = Math.min((this.state.onboardingStep + 1), MAX_STEPS_ALLOWED)
        const options = {
          ...JSON.parse(entityObject.data.options || '{}'),
          onboarding_step: currentStep,
        }
        entityObject.setRecordId(this.state.communityId, this.state.accountId)
        entityObject.data.options = JSON.stringify(options)
        entityObject.save().then(() => {
          if (this.state.onboardingStep === ONBOARDING_STEPS.DESIGN_ONBOARDING) {
            this.getEmailLayoutInfo()
          }
          if (this.state.onboardingStep === ONBOARDING_STEPS.TEMPLATE_ONBOARDING) {
            this.onModalClose(this.props.data[this.key].data)
          }
          return this.setState({
            isSaving: false,
            onboardingStep: currentStep,
          }, () => {
            this.setFooterText()
          })
        })
      })
    }))
  }

  protected getFooterContent(data: any) {
    return <div className="footer">
      {this.state.footerText}
    </div>
  }

  protected saveDisabled(data: any): boolean {
    return this.state.isSaving
  }

  protected getTitleText(data: any): string {
    if (this.state.onboardingStep === ONBOARDING_STEPS.TEMPLATE_ONBOARDING) {
      return 'Choose a template'
    } else {
      return this.props.title
    }
  }

  protected renderChildren(data: User) {
    return <div className="onboarding-modal">
      {this.state.isSaving || this.state.isLoading ? <Loading size="64" /> :
      <div className="content">
        {this.state.onboardingStep === ONBOARDING_STEPS.SOURCE_ONBOARDING && this.getSourceFeedsHtml()}
        {this.state.onboardingStep === ONBOARDING_STEPS.DESIGN_ONBOARDING && this.getDesignHtml()}
        {this.state.onboardingStep === ONBOARDING_STEPS.TEMPLATE_ONBOARDING && this.getTemplatesHtml()}
      </div>}
    </div>
  }

  private saveSourceFeeds = () => {
    const selectedSources = this.state.sources.filter((source) => {
      return source.checked && source.url
    })
    return Promise.all(selectedSources.map((source) => {
      return this.saveSourceFeed(source)
    }))
  }

  private saveSourceFeed = (source: Source) => {
    return this.context.entityMetadata.getEntityObject('communitySource').then(
      (entityObject: any ) => {
      entityObject.setRecordId(this.state.communityId, null)
      entityObject.data.name = source.name === FEED_SOURCE ? this.state.companyName : source.title
      entityObject.data.community_id = this.state.communityId
      entityObject.data.subscriptionTypeId = SubscriptionTypes.preferred
      entityObject.data.identifier = source.url
      entityObject.data.type = source.source_type
      entityObject.save()
    })
  }

  private saveDesign = () => {
    return this.context.entityMetadata.getEntityObject('email_layout').then(
      (entityObject: any ) => {
      entityObject.setRecordId(this.state.communityId, this.state.emailLayoutId)
      Object.keys(this.state.emailLayout).forEach((key) => {
        entityObject.data[key] = this.state.emailLayout[key]
      })
      if (this.state.onboardingStep === ONBOARDING_STEPS.DESIGN_ONBOARDING) {
        entityObject.data[EmailAttributes.brandHeaderImageUrl] = this.getCompanyLogoUrl()
      }
      entityObject.save()
    })
  }

  private fetchCompanyInfo = () => {
    const url: string = AjaxWrapper.getServerUrl() + '/company-info'

    AjaxWrapper.ajax(url, HttpMethod.POST, {
      input: this.state.website,
    })
    .then((response) => {
      const promises = []
      const sources = Sources.map((source) => {
        if (source.name === FEED_SOURCE && !response[source.name]) {
          promises.push(this.fetchSource({
            ...source,
            site_url: response.url,
          }))
        } else if (response[source.name]) {
          promises.push(this.fetchSource({
            ...source,
            site_url: response.url,
            url: response[source.name],
          }))
        }
        return {
          ...source,
          url: response[source.name],
          showTextbox: response[source.name] ? false : true,
        }
      })

      Promise.all(promises).then(() => {
        const foundSources = this.state.sources.filter((source) => source.fetched_sources.length > 0)
        if (foundSources.length === 0 && this.state.onboardingStep === ONBOARDING_STEPS.SOURCE_ONBOARDING) {
          this.setState({
            onboardingStep: ONBOARDING_STEPS.DESIGN_ONBOARDING,
          })
          this.setFooterText()
        }
        this.setState({
          isLoading: false,
        })
      })

      this.setState({
        colors: response.colors,
        emailLayout: {
          ...this.state.emailLayout,
          [EmailAttributes.primaryColor]: response.colors.length ? response.colors[0] : '',
          [EmailAttributes.secondaryColor]: response.colors.length > 1 ? response.colors[1] : '',
        },
        companyInfo: response,
        sources,
      })
      this.setFooterLinks(response)
    })
    .catch((error) => {
      // silently swallow any errors.
    })
  }

  private setFooterLinks = (response) => {
    if (response.twitter) {
      this.setState({
        emailLayout: {
          ...this.state.emailLayout,
          [EmailAttributes.twitterName]: `${TWITTER_FOOTER_LINK_PREFIX}${response.twitter}`,
        },
      })
    }
    if (response.linkedin) {
      this.setState({
        emailLayout: {
          ...this.state.emailLayout,
          [EmailAttributes.linkedInName]: `${LINKEDIN_FOOTER_LINK_PREFIX}${response.linkedin}`,
        },
      })
    }
    if (response.facebook) {
      this.setState({
        emailLayout: {
          ...this.state.emailLayout,
          [EmailAttributes.facebookName]: `${FACEBOOK_FOOTER_LINK_PREFIX}${response.facebook}`,
        },
      })
    }
  }

  private getEmailLayoutInfo = () => {
    this.context.entityMetadata.getEntityObject('email_layout').then(
      (entityObject: any ) => {
      entityObject.load(this.state.communityId, this.state.emailLayoutId).then(() => {
        this.setState({
          emailLayout: {
            ...entityObject.data,
          },
          isLoading: this.state.onboardingStep === ONBOARDING_STEPS.TEMPLATE_ONBOARDING ? false : this.state.isLoading,
        })
        if (this.state.onboardingStep === ONBOARDING_STEPS.DESIGN_ONBOARDING) {
          this.setState({
            emailLayout: {
              ...this.state.emailLayout,
              [EmailAttributes.primaryColor]: this.state.colors.length ? this.state.colors[0] : '',
              [EmailAttributes.secondaryColor]: this.state.colors.length > 1 ? this.state.colors[1] : '',
              [EmailAttributes.newsletterFontFamily]: 'Arial',
            },
          })
        }
      })
    })
  }

  private onCheckToggle = (sourceName: string) => {
    this.setState({
      sources: this.state.sources.map((item) => {
        return {
          ...item,
          checked: item.name === sourceName ? !item.checked : item.checked,
        }
      }),
    })
  }

  private fetchSource = (source: Source) => {
    let url: string = `${AjaxWrapper.getServerUrl()}/${source.fetch_url}`
    let payload: any = {}
    let httpMethod = HttpMethod.POST
    if (source.name === FEED_SOURCE) {
      if (source.url) {
        payload = {
          application: 'dashboard',
          feedUrl: source.url,
          identifier: this.state.communityId,
        }
      } else {
        url = `${AjaxWrapper.getServerUrl()}/${source.find_url}`
        payload = {
          application: 'dashboard',
          feedUrl: source.site_url,
          identifier: this.state.communityId,
        }
      }
    } else {
      url += source.url
      httpMethod = HttpMethod.GET
    }
    return AjaxWrapper.ajax(url, httpMethod, payload)
    .then((response) => {
      const foundSources = response.items ? response.items : response
      this.setState({
        sources: this.state.sources.map((item) => {
          return {
            ...item,
            fetched_sources: item.name === source.name ? foundSources : item.fetched_sources,
            url: response.url || item.url,
          }
        }),
      })
    }).catch((error) => {
      this.setState({
        sources: this.state.sources.map((item) => {
          return {
            ...item,
          }
        }),
      })
    })
  }

  private getSourceFeedsHtml = () => {
    return <div className="source-feeds">
      {this.state.sources.filter((x) => x.fetched_sources.length > 0).map((source: Source) =>
      <div className="source-feed">
        <div className="source-feed-checkbox">
        <div onClick={() => this.onCheckToggle(source.name)}>
          <button className="checkbox-button">
            <div className="feature-icon">{source.checked ? <i className="fas fa-check"></i> : null}</div>
          </button>
        </div>
        </div>
        <div className="icon">
          {source.icon}
        </div>
        <div className="account-info">
          <div className="title">
            {source.title}
          </div>
          <div className="url">
            {source.showTextbox ?
              <div className="url-input">
                <input type="text" placeholder={source.url_placeholder}
                  onChange={(e) => {
                  this.setState({
                    sources: this.state.sources.map((item) => {
                      return {
                        ...item,
                        url: item.name === source.name ? e.target.value : item.url,
                      }
                    }),
                  })
                }}
                value={source.url || ''} />
                {source.fetched_sources.length === 0 &&
                <div onClick={() => this.fetchSource(source)} className="url-text-edit">
                  Fetch
                </div>}
              </div> :
              <div className="url-text">
                {source.url}
                <div onClick={() => {
                  this.setState({
                    sources: this.state.sources.map((item) => {
                      return {
                        ...item,
                        showTextbox: item.name === source.name ? true : item.showTextbox,
                      }
                    }),
                  })
                }} className="url-text-edit">
                  Change
                </div>
              </div>}
          </div>
        </div>
      </div>)}
    </div>
  }

  private getCompanyLogoUrl = () => {
    return this.state.emailLayout[EmailAttributes.brandHeaderImageUrl] ||
      this.state.companyInfo.logoUrl
  }

  private getDesignHtml = () => {
    const companyLogo = this.getCompanyLogoUrl()
    return <div className="design">
      <div className="design-item">
        <div className="title">
          Company Logo
        </div>
        <div className="description">
          Use a high quality image. Only JPG or PNG allowed.
        </div>
        <div className="design-component">
          <Dropzone onDrop={(acceptedFiles) => this.uploadImage(acceptedFiles[0])}
            accept="image/*">
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()} className="lined-box clickable-item">
                  <input {...getInputProps()} />
                  <div className="image-or-placeholder-wrapper">
                    {companyLogo ?
                    <img src={sizedUrl(companyLogo,
                      150, 300, false)}/> : null}
                    {!companyLogo ?
                    <div className="placeholder-wrapper">
                      <div className="upload-wrapper">
                        <div className="upload"><Upload/></div>
                      </div>
                      <div className="upload-text-wrapper">
                        <p className="row1-text">Upload</p>
                        <p className="row2-text">You may drag and drop your file as well.</p>
                        <p className="row3-text">Upload only PNG, JPG.</p>
                      </div>
                    </div> : null}
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
      <div className="design-item">
        <div className="title">
          Primary Color
        </div>
        <div className="design-component">
          <SelectColor
            key={EmailAttributes.primaryColor}
            className={'primary'}
            selectColor={(c: string) => {
              this.setState({
              emailLayout: {
                ...this.state.emailLayout,
                [EmailAttributes.primaryColor]: c,
              },
            })
            }}
            color={this.state.emailLayout[EmailAttributes.primaryColor]} />
        </div>
      </div>
      <div className="design-item">
        <div className="title">
          Secondry Color
        </div>
        <div className="design-component">
          <SelectColor
              key={EmailAttributes.secondaryColor}
              className={'primary'}
              selectColor={(c: string) => this.setState({
                emailLayout: {
                  ...this.state.emailLayout,
                  [EmailAttributes.secondaryColor]: c,
                },
              })}
              color={this.state.emailLayout[EmailAttributes.secondaryColor]} />
        </div>
      </div>
      <div className="design-item">
        <div className="title">
          Font Style
        </div>
        <div className="design-component">
          <DropdownWithDividersComponent
            data={FONT_FAMILIES}
            selected={getFontFamily(this.state.emailLayout[EmailAttributes.newsletterFontFamily])}
            onChange={(e) => this.setState({
              emailLayout: {
                ...this.state.emailLayout,
                [EmailAttributes.newsletterFontFamily]: e.target.value,
              },
            })}
          />
        </div>
      </div>
    </div>
  }

  private isSelected = (template) => {
    return template === this.state.emailTemplate
  }

  private getTemplatesHtml = () => {
    return <div className="templates">
      <div className="template-items">
        <div className={`template-item ${this.isSelected(EmailTemplates.newspaper) ? 'selected' : ''}`}
          onClick={() => this.setTemplate(EmailTemplates.newspaper)}>
          <div className="title">
            Newspaper
          </div>
          <div className="icon">
            <NewspaperTemplate colors={this.state.emailLayout} />
          </div>
        </div>
        <div className={`template-item ${this.isSelected(EmailTemplates.default) ? 'selected' : ''}`}
          onClick={() => this.setTemplate(EmailTemplates.default)}>
          <div className="title">
            Classic
          </div>
          <div className="icon">
            <ClassicTemplate colors={this.state.emailLayout} />
          </div>
        </div>
        <div className={`template-item ${this.isSelected(EmailTemplates.bigAndBold) ? 'selected' : ''}`}
          onClick={() => this.setTemplate(EmailTemplates.bigAndBold)}>
          <div className="title">
            Big and Bold
          </div>
          <div className="icon">
            <BigAndBoldTemplate colors={this.state.emailLayout} />
          </div>
        </div>
        <div className={`template-item ${this.isSelected(EmailTemplates.insights) ? 'selected' : ''}`}
          onClick={() => this.setTemplate(EmailTemplates.insights)}>
          <div className="title">
            Insights
          </div>
          <div className="icon">
            <InsightsTemplate colors={this.state.emailLayout} />
          </div>
        </div>
      </div>
    </div>
  }

  private setTemplate = (template: EmailTemplates) => {
    this.setState({
      emailTemplate: template,
      emailLayout: {
        ...this.state.emailLayout,
        [EmailAttributes.emailTemplate]: template,
      },
    })
  }

  private uploadImage(image: any) {
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.state.communityId}/image`
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage) => {
        const aspectRatio = hostedImage.width / hostedImage.height
        const newWidth = hostedImage.width < HEADER_LOGO_MAX_WIDTH ? hostedImage.width : HEADER_LOGO_MAX_WIDTH
        const newHeight = newWidth / aspectRatio
        this.setState({
          emailLayout: {
            ...this.state.emailLayout,
            [EmailAttributes.logoSize]: `${newHeight}px`,
            [EmailAttributes.brandHeaderImageUrl]: hostedImage.url,
          },
        })
      })
  }

  private doStepSave = (): Promise<any> => {
    if (this.state.onboardingStep === ONBOARDING_STEPS.SOURCE_ONBOARDING) {
      return this.saveSourceFeeds()
    } else if (this.state.onboardingStep === ONBOARDING_STEPS.DESIGN_ONBOARDING ||
      this.state.onboardingStep === ONBOARDING_STEPS.TEMPLATE_ONBOARDING) {
      return this.saveDesign()
    }
    return Promise.resolve('Coming soon!')
  }

  private setFooterText = () => {
    let footerText = FOOTER_TEXT.SOURCE_ONBOARDING
    if (this.state.onboardingStep === ONBOARDING_STEPS.DESIGN_ONBOARDING) {
      footerText = FOOTER_TEXT.DESIGN_ONBOARDING
    } else if (this.state.onboardingStep === ONBOARDING_STEPS.TEMPLATE_ONBOARDING) {
      footerText = FOOTER_TEXT.TEMPLATE_ONBOARDING
    }
    this.setState({
      footerText,
    })
  }

}
