import { addNewsletterUrl } from 'generic/utility'
import React from 'react'
import * as Modals from 'shared/modals'
import { BillingPlan, BillingPlanDetail } from 'shared_server_client/types/billing_plan'
import { User } from 'user'
import * as Constants from '../constants'

declare const RASA_IMAGE_API_ENDPOINT: string

export class AppSumoWelcomeAboard extends Modals.ModalComponent {

  public static key: string = 'welcomeAboard'
  constructor(props: Modals.ModalComponentProps) {
    super(props, AppSumoWelcomeAboard.key)
  }

  protected showHeader(data: any) {
    return false
  }

  protected getCloseText(data: any): string {
    return 'Guided Setup'
  }

  protected close(data: any) {
    window.location.href = addNewsletterUrl()
  }

  protected renderChildren(data: User) {
    if ( !data.activeCommunity || !data.activeCommunity.billingInfo ) {
      return null
    }
    const plan: BillingPlan = data.activeCommunity.billingInfo.currentPlan
    return <div className="appsumo-modal">
      <div className="banner">
        <img src={`${RASA_IMAGE_API_ENDPOINT}/rasa-logos/path/logos/appsumo/as-appsumo-logo-color.png?w=1500&h=240`} style={{width: '200px'}}/>
      </div>
      <div className="title">
        Welcome Sumo-ling!
      </div>
      <div className="content">
        <span>Your {plan.name} includes:</span>
        <div className="plan-list">
          <ul>
            {(plan.details || []).filter((d: BillingPlanDetail) => d.display)
                                 .map((d: BillingPlanDetail, idx: number) => {
              return <li key={idx}>{d.billing_plan_detail_description}</li>
            })}
          </ul>
        </div>
      </div>
      <div>
        <span className="add-newsletter-msg">{Constants.ADD_NEWSLETTER_MSG}</span>
      </div>
    </div>
  }

}
