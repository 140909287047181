import * as React from 'react'
import {connect} from 'react-redux'
import { Row } from 'reactstrap'
import { OnboardingContactsComponent } from '../contacts'
import { OnboardingScheduleComponent } from '../schedule'
import './_styles.scss'
import { OnboardingSourcesMobileComponent } from './sources'

class OnboardingDashboardComponent extends React.Component<any> {

  constructor(p: any) {
    super(p)
  }

  public render = () => {
    return <div className="option-wrapper">
      <div className="clearfix"></div>
      <div className="onboarding-dashboard-container">
        <Row>
          <OnboardingSourcesMobileComponent/>
          <OnboardingContactsComponent/>
          <OnboardingScheduleComponent/>
        </Row>
        <span className="break"/>
      </div>
      </div>}}

export const OnboardingMobileDashboard = connect(
  null,
  {},
)(OnboardingDashboardComponent)
