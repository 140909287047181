import * as React from 'react'

export const ALLOWED_SIGNUP_SOURCES = ['New Onboarding UI']

export interface DashboardVideo {
    description: string,
    clickUrl?: string,
    clickTitle?: string,
    guid: string,
    title: string,
  }

export const DASHBOARD_VIDEOS: DashboardVideo[] = [
  {
    clickUrl: '/content/sources',
    clickTitle: 'Go to Sources',
    description: 'A source can be anything that publishes relevant content for your newsletter.',
    guid: 'sveqic0aef',
    title: 'Add your Content',
  },
  {
    description: 'Email newsletters remain one of the more effective ways to reach your audience. Consumers are also more aware of (and annoyed by) spam email. The result? Welcome to the age of personalization.',
    guid: 'sveqic0aef',
    title: 'Why now is a good time to send a newsletter',
  },
]

export const scriptUrl = (video: DashboardVideo): string => `https://fast.wistia.com/embed/medias/${video.guid}.jsonp`

export const ADD_NEWSLETTER_MSG = <div>
  The first step is to go through our Guided Setup. <br></br>
  We’ll walk you through how to set up your newsletter.<br></br>
  Let’s get started!
</div>
